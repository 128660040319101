main {
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
width: 40rem;
height: 32rem;
background-color: #ffffff;
-webkit-box-shadow: 0px 5px 15px 8px #e4e7fb;
box-shadow: 0px 5px 15px 8px #e4e7fb;
border-radius: 0.5rem;
margin: 0 auto;
}

#header {
width: 100%;
display: flex;
align-items: center;
justify-content: space-between;
padding: 2.5rem 2rem;
}



.rank {
font-family: "Rubik", sans-serif;
font-size: 1.7rem;
color: #141a39;
text-transform: uppercase;
cursor: default;
}

#leaderboard {
width: 100%;
position: relative;
}

table {
width: 100%;
border-collapse: collapse;
table-layout: fixed;
color: #141a39;
cursor: default;
}

tr {
transition: all 0.2s ease-in-out;
border-radius: 0.2rem;
}

tr:not(:first-child):hover {
background-color: #fff;
transform: scale(1.1);
-webkit-box-shadow: 0px 5px 15px 8px #e4e7fb;
box-shadow: 0px 5px 15px 8px #e4e7fb;
}

tr:nth-child(odd) {
background-color: #f9f9f9;
}

tr:nth-child(1) {
color: #fff;
}

td {
height: 5rem;
font-family: "Rubik", sans-serif;
font-size: 1.4rem;
padding: 1rem 2rem;
position: relative;
}

.number {
width: 1rem;
font-size: 2.2rem;
font-weight: bold;
text-align: left;
}

.name {
text-align: left;
font-size: 1.2rem;
}

.points {
font-weight: bold;
font-size: 1.3rem;
display: flex;
justify-content: flex-end;
align-items: center;
}

.points:first-child {
width: 10rem;
}

.gold-medal {
height: 3rem;
margin-left: 1.5rem;
}

.ribbon {
width: 42rem;
height: 5.5rem;
top: -0.5rem;
background-color: #5c5be5;
position: absolute;
left: -1rem;
-webkit-box-shadow: 0px 15px 11px -6px #7a7a7d;
box-shadow: 0px 15px 11px -6px #7a7a7d;
}

.ribbon::before {
content: "";
height: 1.5rem;
width: 1.5rem;
bottom: -0.8rem;
left: 0.35rem;
transform: rotate(45deg);
background-color: #5c5be5;
position: absolute;
z-index: -1;
}

.ribbon::after {
content: "";
height: 1.5rem;
width: 1.5rem;
bottom: -0.8rem;
right: 0.35rem;
transform: rotate(45deg);
background-color: #5c5be5;
position: absolute;
z-index: -1;
}



@media (max-width: 740px) {
    * {
    font-size: 70%;
    }
}

@media (max-width: 500px) {
    * {
    font-size: 55%;
    }
}

@media (max-width: 390px) {
    * {
    font-size: 45%;
    }
}