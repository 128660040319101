/* Resetting default margin and padding for all elements */
* {
	margin: 0;
	padding: 0;
}

/* Styles for the navigation bar */
.navbar {
	display: flex; /* Display as a flex container */
	align-items: center; /* Center items vertically */
	justify-content: space-between; /* Space items evenly along the main axis */
	position: sticky; /* Positioning type: sticky */
	top: 0; /* Stick to the top of the viewport */
    width: 100%; /* Full width */
    height: 70px; /* Fixed height for the navbar */
    padding: 1rem; /* Padding around the navbar content */
    z-index: 10; /* Stack order */
}

/* Background style for the navigation bar */
.background {
	background: rgba(255,255,255, 1); /* White background color */
	background-blend-mode: darken; /* Blend mode for background */
	background-size: cover; /* Cover the entire background */
}

/* Styles for the navigation links list */
.nav-list {
	display: flex; /* Display as a flex container */
	align-items: center; /* Center items vertically */
}

/* Styles for individual list items in the navigation links list */
.nav-list li {
	list-style: none; /* Remove default list styles */
	padding: 26px 15px; /* Padding around each link */
}

/* Styles for anchor tags (links) in the navigation links list */
.nav-list li a {
	text-decoration: none; /* Remove underline from links */
	color: #03045E; /* Link text color 001D4A*/
	font-size: 22px;
	font-weight: 500;
}

/* Hover effect for anchor tags in the navigation links list */
.nav-list li a:hover {
    /*color: rgb(94, 45, 145, 0.8); /* Change text color on hover */
	color: black;
}

/* Styles for the logo */
.logo {
	display: flex; /* Display as a flex container */
	align-items: center; /* Center items vertically */
}

/* Styles for the logo image */
.logo img {
	height: 190px; /* Fixed height for the logo image */
	width: auto; /* Auto-adjust width */
	margin-top: 1.5rem;
	margin-left: -1.2rem;
}

/* Active link styling */
.nav-list li a.active {
	/*text-decoration: underline; /* Underline active links */
	background-color: #27476E;
	padding: 10px;
	border-radius: 0.5rem;
	font-weight: bold;
	color: white
}



