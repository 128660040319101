/* src/pages/InvestmentScams.css */
.scams-container {
    padding: 20px;
    text-align: left;
    font-family: Arial, sans-serif;
    border-radius: 10px;
  }
  .scam-intro{
    background-image: url('../assets/typescamsubbackbround.png');
    background-size: cover;
    /* background-attachment: fixed; */
  }
  
  .type-title {
    font-size: 2em;
    color: #03045E;
    margin-bottom: 30px;
    margin-left: 100px;
    margin-top: -10px;
    padding: 0px;
    padding-top: 20px;
  }
 
  
  .type-subtitle {
    font-size: 1.2em;
    color: black;
    font-weight: bold;
    margin-bottom: 40px;
    margin-left: 100px;
    margin-top: 0;
  }
  
  .type-info-box {
    padding: 50px;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: left;
    color: #333;
    margin-left: 100px;
    margin-right: 100px;
    margin-bottom: 50px;
  
    font-size: medium;
  }
  .type-info-box p{
    padding: 10px;

  }
  
  .red-flags-title {
    font-size: 1.5em;
    color: #03045E;
    margin: 20px 0;
    margin-left: 100px;
  }
  
  .red-flags-box {
    padding: 20px;
    background-color: #f4c2c2;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: left;
    color: #333;
    margin-bottom: 50px;
    margin-left: 100px;
    margin-right: 100px;
    
  }
  
  .red-flags-box ul {
    list-style: disc inside;
  }
  
  .red-flags-box li {
    margin-left: 0px;
    margin-bottom: 10px;
    padding: 8px;
  }
  
  .red-flags-box strong {
    color: #03045E;
  }

  .tips-title{
    margin-left: 20px;
    font-size: 1.5em;
    color: #03045E;
    margin: 20px 0;
    margin-left: 100px;
  }
  .tips{
    padding: 20px;
    background-color: #F5F5DC;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: left;
    color: #333;
    margin-left: 100px;
    margin-right: 100px;

  }
  .tips ul {
    list-style: disc inside;
  }
  
  .tips li {
    margin-bottom: 10px;
    margin-left: 0px;
    padding: 8px;
  }
  .tips li a {

    font-size: small;
    font-weight: bold;
    color: #03045E;
    text-decoration: underline;
    
  }
  /* .romance-image-style {
    background-image: url('../assets/Romance1.png');
    background-size: cover;
    background-position: center;
}
.unexpected-image-style {
  background-image: url('../assets/Unexpected1.png');
  background-size: cover;
  background-position: center;
}
.threats-image-style {
  background-image: url('../assets/Threats1.png');
  background-size: cover;
  background-position: center;
}
.impersonation-image-style{
  background-image: url('../assets/Impersonation1.png');
  background-size: cover;
  background-position: center;
} */
  