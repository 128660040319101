/* ScamTypes.css */
.scam-types-container {
  padding: 20px;
  text-align: center;
  font-family: Arial, sans-serif;
}

.scam-types-title {
  font-size: 2.0em;
  color: #03045E;
  margin-top: 20px;
  margin-bottom: 50px;
}

.scam-cards {
  display: flex;
  flex-wrap: wrap; /* Allows the cards to wrap on smaller screens */
  justify-content: center; /* Center the cards horizontally */
  gap: 40px; /* Adjust gap between the cards */
  margin: 50px auto; /* Vertically space and horizontally center the container */
  width: 80%;
  max-width: 1000px;
}

.scam-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  width: 120%; /* Adjust based on layout needs */
  margin-bottom: 30px;
}

.scam-icon {
  width: 100px;
  height: 100px;
  margin-right: 15px;
}

.scam-info {
  flex-grow: 1;
  text-align: left;
}

.scam-info h3 {
  font-size: 1.5em;
  margin: 0;
  color: #333;
  font-weight: bold;
  margin-bottom: 20px;
}

.scam-info p {
  font-size: 0.9em;
  color: #555;
  font-weight: bold;
  line-height: 1.5;
}

.scam-arrow {
  font-size: 2em;
  color: #03045E;
}