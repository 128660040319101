* {
	margin: 0;
	padding: 0;
}

/*homepage*/
.content {
	display: flex;
	align-items: center;
	justify-content: center;
  text-align: center;
  /*margin-top: 6rem;
  margin-bottom: 6rem;*/
  padding: 9rem;
}

.box-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 70%;
  margin: auto;
}

.centered-title {
  font-size: 3rem;
  color: #001D4A;
}

.centered-description {
  font-size: 2rem;
  font-weight: 350;
  margin-bottom: 2rem;
  margin-top: 1rem;
  color: #001D4A;
}

/*click button on home page*/
.btn {
  padding: 12px 32px;
  border-radius: 30px;
  font-size: 1.5rem;
  background: #5E2D91;
  color: #fff;
  border: 1px solid #5E2D91;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
}

.btn:hover {
  /*background: rgba(94,45,145, 0.7);*/
  background: #4a226a;
  border: 1px solid #4a226a;
}

/* Content section styles */
.content-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}

/* Text content styles */
.text-content {
  width: 450px;
  padding: 10px;
  margin: auto;
  text-align: left;
  white-space: normal; /* Allow text to wrap */
  word-wrap: break-word; /* Allow long words to break */
  overflow-wrap: break-word; /* Ensure words break at appropriate places */
}

.home-text {
  font-size: 18px;
  line-height: 2rem;
  font-family: 'Plus Jakarta Sans';
}

/* Image container styles */
.image-container {
  margin: 120px auto;
  margin-bottom: 80px;
}

/* Image styles */
.image-container img {
  max-width: 100%;
  max-height: 350px;
}


/*homepage cards*/
.card-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  padding: 5rem;

  /*margin-bottom: 5rem;*/
}

.flex-container {
  display: flex;
  flex-direction: row; /* Ensure it's set to row to have items side by side */
  justify-content: space-between; /* Adjusts the spacing between the items */
  align-items: start; /* Aligns items to the top of the container */
  width: 100%; /* Use the full available width */
}

.card-container, .input-area {
  flex: 1; /* This will allow each section to take up equal space */
  padding: 2px; /* Adds some padding inside each container for spacing */
  box-sizing: border-box; /* Ensures padding is included in width calculation */
}
.card-containerd, .input-area {
  flex: 1; /* This will allow each section to take up equal space */
  padding: 2px; /* Adds some padding inside each container for spacing */
  box-sizing: border-box; /* Ensures padding is included in width calculation */
}

@media (max-width: 768px) {
  .flex-container {
      flex-direction: column;  
  }
}


.info-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  background-color: rgb(255, 255, 255); /* white background */
  padding: 20px;
  border-radius: 40px; /* Rounded corners */
  box-shadow: 0 2px 4px rgba(0,0,0,0.2); /* Soft shadow */
  margin: 20px; /* Spacing between cards and other elements */
  width: 400px; /* Adjust width of the card */
  height: 300px /* Limits card size, increase if you want larger cards */
}

.card-content {
  flex: 1; /* Fill the remaining space */
  position: relative; /* This is necessary if you're using absolute positioning for children */
  padding-bottom: 70px; 
}

.card-link-button {
  padding: 10px 20px; /*border*/
  background-color: #5E2D91; /* Default background color set to orange */
  text-decoration: none;
  border-radius: 30px; /* Fully rounded edges for button */
  position: absolute;
  display: flex;
  border: 2px solid transparent; /* Transparent border to maintain the layout */
  margin-top: 1rem;
  margin-left: 7rem;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
}

/*card text*/
.card-heading {
  color: #5E2D91;
  line-height: 1.8;
}

.card-link {
  color: white;
	font-size: 18px;
}

.card-link:hover {
	font-size: 18px;
  text-decoration: underline;
}
/*end of card text*/
/*end of homepage cards*/
/*end of homepage*/


/*detect scam*/

.card-containerd {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  /*margin-bottom: 5rem;*/
}



@media (min-width: 768px) {
  .check-button {
    padding: 0.25em 0.75em;
  }
}

.clear-Button{
  margin-left: 10px;
}

.checkclear-container {
  display: flex;
  gap: 10px; /* Creates space between any child elements */
}

/* Added styles for User Guide */
.info-cardx {
  padding: 20px;
	border-radius: 10px;
	width: 400px;
  height: auto;
	background-color: rgba(255, 255, 255, 0.7);
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
	text-align: left; /* Changed from center to left */
	margin-top: 2rem;
}

.card-contentx {
  padding: 10px;
  margin-left: 15px;
  margin-right: 15px;
  line-height: 1.95;
	/*margin-left: 10px; /* Added left margin for better alignment */
}

.info-cardx h3 {
	margin-bottom: 10px;
  text-align: center;
}

.search-tool {
	height: auto;
	display: flex;
	align-items: center;
	justify-content: space-between;
  margin-top: 5rem;
  /* margin-bottom: 5rem; */
  text-align: center;
}

.centered-text {
  font-family: "Open Sans", sans-serif;
  font-size: 28px;
  font-weight: 500;
  color: black;
  line-height: 2;
  text-align: center;

  /*margin-bottom: 2rem;*/
}

.search-box {
  display: flex;
  width: 100%;
  max-width: 600px; /* Adjust as needed */
  margin: 0 auto; /* Center the box horizontally */
  margin-bottom: 1rem;
  margin-top: 2rem;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
}

.search-box input[type="text"] {
  height: 150px;
  width: 600px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 10px;
  font-size: 1.5rem;
  text-align: center;
}

.searchButton {  
  padding: 12px 32px; /*border size*/
  border-radius: 30px;
  background: #5E2D91;
  border: 1px solid #5E2D91;
  font-size: 1.5rem;
  color: #fff;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
}

.searchButton:hover {  
  /*background: rgba(94,45,145, 0.7);*/
  background: #4a226a;
  border: 1px solid #4a226a;
}

.clearButton {
  margin-left: 10px;   
  padding: 12px 32px; /*border size*/
  border-radius: 30px;
  background: #5E2D91;
  border: 1px solid #5E2D91;
  font-size: 1.5rem;
  color: #fff;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
}
  
.clearButton:hover {  
    background: rgba(94,45,145, 0.7);
}

@media (max-width: 768px) {
  .button, .check-button, .clear-Button {
      width: 100%; /* Full width buttons on smaller screens */
      margin-bottom: 10px;
  }
}

.result-section{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center; 
  justify-content: center; 
}



.result-section1 {
  font-family: "Open Sans", sans-serif;
  width: 50%;
  max-width: 90%; /* Limit maximum width to ensure it doesn't get too wide */
  margin: 20px auto; /* Center it horizontally and add vertical spacing */
  padding: 15px 20px;
  font-size: 16px;
  letter-spacing: 2px;
  align-items: center; 
  position: relative;
  justify-content: center; 
  text-decoration: none;
  color: #000;
  background-color: white;
  border: 3px solid;
  padding: 0.25em 0.5em;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.05); /* Single layer, more blur, lighter opacity */

}

@keyframes jump {
  0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
  }
  40% {
      transform: translateY(-10px);
  }
  60% {
      transform: translateY(-5px);
  }
}

.jumping-icon {
  display: inline-block;
  animation: jump 1s infinite;
}


.search-results {
  width: 80%; /* Adjusts the width of the results container */
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Optional: Adds subtle shadow for better visibility */
  background: #fff; /* Optional: Sets a background color for contrast */
  border-radius: 8px; /* Optional: Rounds the corners */
}

/* Result text
.search-results {
  padding: 20px;
  background: #f9f9f9; 
  border: 2px solid #dcdcdc; 
  border-radius: 4px; 
  margin-top: 10px;
  margin-bottom: 50px;
  height: 60px;
  width: 300px;
  text-align: center;
} */

.search-results:empty {
  display: none;
}

.message-upload {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;
}

/* .result-actions{
  padding-top: 30px;
} */

.result-actions{

    width: 100%; /* Ensure the div takes the full width of its parent */
    display: flex;
    justify-content: space-between; /* Aligns children at both ends */
    padding-top: 30px; /* Keeps existing top padding */
    padding-bottom: 20px; /* Adds bottom padding to the container */
  
}

.learn-more, .homeButton {
  cursor: pointer;
  font-weight: 700;
  font-family: Helvetica, "sans-serif";
  transition: all .2s;
  padding: 10px 20px; /* Adjust padding if necessary */
  border-radius: 100px;
  background: white;
  border: 1px solid;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  margin: 10px 20px;
  min-width: 120px; /* Ensure the button is not too narrow */
  text-align: center;
}
.homeButton:first-child {
  margin-left: 10px;
  margin-right: 10px; /* Ensures space between buttons if next to each other */
}
.learn-more:last-child {
  margin-right: 10px;
}

.learn-more:hover {
  background: #CAF0F8; /* Fixed selector */
}
.homeButton:hover{
  background : #CAF0F8;
}

.learn-more svg, .homeButton svg {
  margin-right: 5px; /* Adjust this value to reduce the gap */
  transition: transform .3s ease-in-out;
}

.learn-more:hover svg, .homeButton:hover svg {
  transform: translateX(5px);
}

.learn-more:active, .homeButton:active {
  transform: scale(0.95);
}

@media (max-width: 768px) {
  .flex-container {
      flex-direction: column;
  }
}

@media (max-width: 768px) {
  .centered-text {
      font-size: 1.5rem; /* Smaller text on tablets */
  }
}

@media (max-width: 480px) {
  .centered-text {
      font-size: 1.2rem; /* Even smaller on phones */
  }
}
.disclaimer {
  display: flex;
  align-items: center;
  font-family: Helvetica, "sans-serif";
  font-size: 16px;
  text-align: justify;
  padding: 0.7em 1.4em; /* Uniform padding */
  color: black;
  background: white;
  border: none;
  font-weight: bold;
  letter-spacing: 0.05em;
  border-radius: 20em;
  line-height: 1.5; /* Increased line spacing for better readability */
}

.disclaimer svg {
  margin-right: 6px;
}


.disclaimer:active {
  box-shadow: 0 0.3em 1em -0.5em black;
}

@media (max-width: 768px) {
  .disclaimer {
    font-size: 14px; /* Smaller font size on tablets and below */
    padding: 10px 20px; /* Slightly smaller padding */
  }
}

@media (max-width: 480px) {
  .disclaimer {
    font-size: 12px; /* Even smaller font size on mobile devices */
    padding: 8px 15px; /* Reduced padding to fit smaller screens */
  }
}



@media (max-width: 768px) {
  .result-actions {
      flex-direction: column;
      align-items: center;
  }

  .homeButton, .learn-more {
      width: 100%; /* Full width for easier access */
      margin-bottom: 10px;
  }
}

/*buttons*/
/* .learn-more,.homeButton {
  padding: 12px 40px;
  font-size: 1rem; 
  border: none; 
  border-radius: 30px; 
  cursor: pointer; 
  background-color: #5E2D91; 
  color: white; 
  margin-right: 10px;
  margin-left: 10px;
  border: 2px solid transparent;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
} */

/* .learn-more:hover, .homeButton:hover {
  text-decoration: underline;
} */

/*media*/
@media (max-width: 768px) {
  .tracker-text {
    font-size: 5vw; 
  }

  input[type="text"],
  .searchButton {
    padding: 0.5em; 
  }

  .search-results,
  .result-actions button {
    max-width: none; 
  }

  .result-actions {
    flex-direction: column; 
  }
}

@media (max-width: 480px) {
  .tracker-text {
    font-size: 6vw; 
  }

  .search-results {
    margin-top: -50px; 
  }
}
/*end of media*/
/*end of detect scam*/



/*help and support*/
.header {
	display: flex;
	align-items: center;
	justify-content: center;
  text-align: center;
  flex-direction: column;
	position: sticky;
	top: 1;
  width: 100%;
  height: 150px;
  padding: 1rem;
  z-index: 10;
  background-color: #006992;
}

.text-header {
  color: white;
  line-height: 1.8;
}

.section {
	height: auto;
	display: flex;
	align-items: center;
	background-color: rgb(250, 250, 250);
	justify-content: space-between;
}

.box-main {
	display: flex;
	justify-content: center;
	align-items: center;
	color: black;
	max-width: 80%;
	margin: 0 9rem;
	height: 80%;
  margin-bottom: 2rem;
  margin-top: 1rem;
}

.firsthalf {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.secondhalf {
	width: 30%;
}

/*heading*/
.text-big {
	font-weight: 600;
	font-family: "Segoe UI", Tahoma, Geneva, Verdana,
		sans-serif;
	font-size: 30px;
  line-height: 1.6;
}

/*text content*/
.text-small {
	font-size: 18px;
  line-height: 2.5rem;
}

.text-link {
	font-size: 18px;
}

.text-link:hover {
	font-size: 18px;
  text-decoration: underline;
}

.text-bold {
  font-weight: 700;
  color: #000;
  font-size: 19px;
  line-height: 1.8;
  margin-top: 2rem;
}

.text-bullet {
  font-weight: 500;
  color: #000;
  font-size: 20px;
  line-height: 1.8;
  margin-left: 2rem;
}

.text-bullet:hover {
  color: grey;
  text-decoration: underline;
}

.indented-list {
  margin-left: 3rem;
}

.statistics-container {
  background-color: #f2f2f2;
  border-radius: 8px;
  padding: 20px;
  margin: 20px 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.statistics-title {
  font-size: 1.5em;
  color: #333;
  margin-bottom: 16px;
}

.counter-graph {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.statistic {
  text-align: center;
  margin: 10px 0;
}

.statistic .label {
  font-size: 1.2em;
  color: #666;
  margin-bottom: 5px;
}

.statistic .value {
  font-size: 2em;
  font-weight: bold;
  color: #333;
}

/* You can adjust the media query breakpoints and styles according to your layout needs */
@media (max-width: 768px) {
  .counter-graph {
    flex-direction: column;
  }

  .statistic {
    margin: 20px 0;
  }
}

/*buttons in help and support page*/
.button {
  background-color: #5E2D91; /* Button color */
  color: #fff; /* Text color */
  border: none; /* Remove default button border */
  padding: 10px 20px; /* Padding inside the button */
  cursor: pointer; /* Show pointer cursor on hover */
  border-radius: 5px; /* Rounded corners */
  font-size: 16px; /* Font size */
}

.button:hover {
  text-decoration: underline; 
}
/*end of help and support*/


/*footer*/
.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(0, 0, 0, 0.8);
  padding: 20px 10px;
  bottom: 0;
  position: static;
  width: 100%;
}

.text-footer {
  margin: 0;
  font-family: "Ubuntu", sans-serif;
  color: #fff;
  text-align: center; /* Center the text */
  flex-grow: 1; /* Allow the center div to grow and take up space */
}





/*end of footer*/




/*quiz*/
.username-input {
  display: flex;
  width: 100%;
   /* Adjust as needed */
  margin: 0 auto; /* Center the box horizontally */
  /*margin-bottom: 10rem;*/
  margin-top: 5rem;
  flex-direction: column;
  align-items: center;
}

.username-input-text {
  margin-top: 1rem;
}

.username-input input[type="text"] {
  height: 50px;
  width: 250px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 10px;
  font-size: 1.2rem;
  text-align: left;
}

.submit-button {
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.quiz-section {
  display: flex;
  flex-direction: column;
  /*justify-content: center;*/
  align-items: center;
  width: 700px;
  height: 470px;
  border-radius: 20px;
  background-color: rgb(250, 250, 250);
  box-shadow: 0 2px 4px rgba(0,0,0,0.2); /* Soft shadow */
  margin: 3rem auto; /* Center the box horizontally */
}

.score-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 240px;
  border-radius: 20px;
  margin: 50px auto; /* Center the box horizontally */
  background-color: rgb(250, 250, 250);
  box-shadow: 0 2px 4px rgba(0,0,0,0.2); /* Soft shadow */
}

.score-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  margin-top: 15px;
}

.question-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
}

.question-text {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin-top: 25px;
}

.question-text img {
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: contain; /* cover or 'contain' */

}

.explanation-section{
  background: #ADE8F4;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2); /* Soft shadow */
  padding: 10px;
  border-radius: 10px;
  align-items: center;
}

.explanation-text {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 20px;
  width: 500px;
}

.question-section .answer-section button {
  margin: 5px;
  cursor: pointer;
  border: none; /* Remove default button border */
  padding: 10px 16px; /* Padding inside the button */
  cursor: pointer; /* Show pointer cursor on hover */
  border-radius: 8px; /* Rounded corners */
  font-size: 15px; /* Font size */
  font-weight: 500;
  font-family: "Haas Grot Text R Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
  height: 40px;
  color: #FFFFFF;
}

.question-section .answer-section button.is-not-scam:hover {
  background-color: #001D4A;
}

.question-section .answer-section button.is-scam:hover {
  background-color: #001D4A;
}

.question-section .answer-section button.is-not-scam {
  background-color: #27476E;
  color: white;
}

.question-section .answer-section button.is-scam {
  background-color: #27476E;
  color: white;
}

.explanation {
  font-family: 'Plus Jakarta Sans';
  font-size: 1.2rem;
  line-height: 1.8rem;
}

.explanation-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  justify-content: justify;
  margin: 0 auto;
}

.quiz-section button {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #5E2D91;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.explanation-section button {
  margin-top: 10px;
  padding: 10px 16px;
  background-color: #27476E;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  font-family: "Haas Grot Text R Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
  height: 40px;
  transition: background-color 0.3s ease;
  margin: 5px;
}

.explanation-section button:hover {
  background-color: #001D4A;
}

.quiz-end {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.exit-button {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #5E2D91;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.exit-button:hover {
  text-decoration: underline;
}

.search-box textarea {
  width: 100%; /* Ensures the textarea takes the full width of its container */
  height: 300px; /* Adjust height to make the textarea larger */
  font-size: 18px; /* Increases the font size for better readability */
  background-color: white;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  border-radius: 10px;
  padding: 10px; /* Adds space inside the border, between the border and text */
  box-sizing: border-box; /* Includes padding and border in the width and height */

}
.search-box textarea:focus {
  border-color: black ;/* Darker blue for better focus visibility */
  outline: none; /* Removes the default focus outline */
  
}

.consent-popup {
  position: absolute;
  bottom: 100%; /* Position above the result section */
  left: 50%;
  transform: translateX(-50%);
  width: 90%; /* Increased width to make the popup larger */
  max-width: 600px; /* Set a maximum width to ensure it doesn't get too wide on large screens */
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px black;
  text-align: justify; /* Justify the text inside the popup */
  z-index: 50; /* High z-index to ensure visibility */
}
.consent-popup p {
  margin-bottom: 20px; /* Add bottom margin to create space between text and buttons */
}

.button-68 {
  appearance: none;
  backface-visibility: hidden;
  border-radius: 8px;
  border-style: none;
  box-shadow: rgba(0, 0, 0, 0.15) 0 4px 9px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: Inter,-apple-system,system-ui,"Segoe UI",Helvetica,Arial,sans-serif;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: normal;
  line-height: 1.5;
  outline: none;
  overflow: hidden;
  padding: 10px 20px; /* Adjusted padding to reduce height */
  position: relative;
  text-align: center;
  text-decoration: none;
  transform: translate3d(0, 0, 0);
  transition: all .3s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: top;
  white-space: nowrap;
  margin-right: 10px; /* Adds space between buttons */
}
.button-68:last-child {
  margin-right: 0; /* Remove margin from the last button (No button) */
}
/* Hover effects for both buttons */
.button-68:hover {
  opacity: 1;
  transform: translateY(0);
  transition-duration: .35s;
}

.button-68:active {
  transform: translateY(2px);
  transition-duration: .35s;
}

.button-yes {
  background-color: #27ae60; /* Green background */
  box-shadow: rgba(39, 174, 96, .15) 0 4px 9px;
}

.button-yes:hover {
  background-color: #1e8449; /* Darker green on hover */
  box-shadow: rgba(39, 174, 96, .2) 0 6px 12px;
}

.button-no {
  background-color: #e74c3c; /* Red background */
  box-shadow: rgba(231, 76, 60, .15) 0 4px 9px;
}

.button-no:hover {
  background-color: #c0392b; /* Darker red on hover */
  box-shadow: rgba(231, 76, 60, .2) 0 6px 12px;
}
.quiz-text {
  font-family: 'Plus Jakarta Sans';
  font-size: 1.2rem;
  line-height: 1.8rem;
}

.question-count {
  font-family: 'Plus Jakarta Sans';
  font-size: 1.6rem;
  font-weight: bold;
  color: #333;
}
/*end of quiz*/








/*-------------------------VISUALISATION CSS------------------------------*/

.hidden {
  opacity: 0.1;
}

.d3-tooltip {
  position: absolute;
  visibility: hidden;
  padding: 6px;
  background: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  pointer-events: none;
}

.legend {
  font-family: 'Arial, sans-serif';
  font-size: 14px;
}

.tooltip {
  pointer-events: none;
  fill: #333;
  font-weight: bold;
}

.visualization-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.image-info-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  width: 100%;
  padding: 20px;
}

.image-info-container .info-text {
  flex-grow: 1;
  font-size: x-large;
  margin-right: 200px;
  padding: 0 40px;
  background: #98c1d9;
  padding: 40px;
  border-radius: 20%;
}

.image-info-container.reverse .info-text {
  margin-left: 200px;
}


.info-image {
  flex-shrink: 0;
  max-width: 400px; /* Adjust based on your image size */
  margin: 0 180px;
}

.info-text {
  max-width: 300px; /* Adjust based on your layout */
  margin: 0 20px;
  text-align: center;
}

.line-chart-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}

.line-chart-container svg {
  height: auto; /* This will keep the aspect ratio of your SVG */
  align-items: center;
  margin-left: 380px;
}

.line-chart-container p {
  margin-top: 20px;
  text-align: center;
  margin-bottom: 100px;
  font-size: x-large;
  margin-left: 200px;
  margin-right: 200px;
}


/* SCAM TREND CSS*/

.chart-with-info-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px; /* Adjust as needed */
  margin-left: 20px;
  margin-right: 20px;
}

.chart-container {
  /* Adjust width and height as necessary to fit your charts */
  width: 60%;
  height: auto;
}

.chart-description {
  width: 35%; /* Adjust as needed */
  padding: 20px;
  text-align: left;
  font-size: x-large;
}

.vis-heading-container {
  margin-top: 40px;
  margin-bottom: 100px;
  
}

.vis-heading-container h1{
  color: #000;
  margin: 20px;
}
.vis-heading-container hr {
  border: none; /* Remove default border */
  height: 2px; /* Set the height of the line */
  background-color: black; /* Set the color of the line */
  margin: 20px 0; /* Add margin above and below the line */
}





.card-heading {
  display: flex;
  justify-content: center;
  margin: auto;
}

.card-container-home {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  margin: auto;
}

.card-home {
  height: 22rem;
  width: 16rem;
  max-width: 300px;
  border-radius: 0.5rem;
  background-color: #fff;
  box-shadow: 20px 20px 30px rgba(0, 0, 0, .05);
  border: 1px solid transparent;
  margin: 1rem;
}

.card-home:hover {
  box-shadow: 20px 20px 30px rgba(0, 0, 0, .15);
}

.card-home a {
  text-decoration: none
}

.card-button {
  position: absolute;
  bottom: 0;
}

.content-home {
  padding: 1.1rem;
}

.content-homex {
  height: 115px;
}

.image-home {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 150px;
  background-color: #AEECEF;
}

.image-home img {
  object-fit: contain; /* cover or 'contain' */
  width: 100%;
  height: 100%;
}

.title-home {
  color: #111827;
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 600;
}

.desc-home {
  margin-top: 0.5rem;
  color: #6B7280;
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-align: justify;
  text-justify: inter-word;
}

.action-home {
  display: inline-flex;
  margin-top: 1rem;
  color: #fff;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  align-items: center;
  gap: 0.25rem;
  background-color: #27476E;
  /*background-color: rgb(239, 205, 255);*/
  padding: 6px 10px;
  border-radius: 4px;
}

.action-home:hover {
  background-color: #001D4A;
}

.action-home span {
  transition: .3s ease;
}

.action-home:hover span {
  transform: translateX(4px);
}
/*end of cards*/

/*button to go up*/
.button-homex {
  display: flex;
  align-items: center;
  justify-content: right;
  margin-right: 20px;
}

.button-home {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: rgb(20, 20, 20);
  border: none;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 0px 4px rgba(180, 160, 255, 0.253);
  cursor: pointer;
  transition-duration: 0.3s;
  overflow: hidden;
  position: relative;
}

.svgIcon {
  width: 12px;
  transition-duration: 0.3s;
}

.svgIcon path {
  fill: white;
}

.button-home:hover {
  width: 140px;
  border-radius: 50px;
  transition-duration: 0.3s;
  background-color: #001D4A;
  /*background-color: #5E2D91;*/
  align-items: center;
}

.button-home:hover .svgIcon {
  /* width: 20px; */
  transition-duration: 0.3s;
  transform: translateY(-200%);
}

.button-home::before {
  position: absolute;
  bottom: -20px;
  content: "Back to Top";
  color: white;
  /* transition-duration: .3s; */
  font-size: 0px;
}

.button-home:hover::before {
  font-size: 13px;
  opacity: 1;
  bottom: unset;
  /* transform: translateY(-30px); */
  transition-duration: 0.3s;
}
/*end of button to go up*/

/*button to go down*/
.button-homex-down {
  display: flex;
  align-items: center;
  justify-content: center;
  
}

.button-home-down {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: rgb(20, 20, 20);
  border: none;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 0px 4px rgba(180, 160, 255, 0.253);
  cursor: pointer;
  transition-duration: 0.3s;
  overflow: hidden;
  position: relative;

}

.button-home-down:hover {
  background-color: #27476E;
}

.svgIcon-down {
  width: 12px;
  transition-duration: 0.3s;
  transform: rotate(-180deg);
}

.svgIcon-down path {
  fill: white;
}
/*end of button to go down*/


/* CSS */
.button-17 {
  align-items: center;
  appearance: none;
  background-color: #fff;
  border-radius: 24px;
  border-style: none;
  box-shadow: rgba(0, 0, 0, .2) 0 3px 5px -1px,rgba(0, 0, 0, .14) 0 6px 10px 0,rgba(0, 0, 0, .12) 0 1px 18px 0;
  box-sizing: border-box;
  color: #3c4043;
  cursor: pointer;
  display: inline-flex;
  fill: currentcolor;
  font-family: "Google Sans",Roboto,Arial,sans-serif;
  font-size: 14px;
  font-weight: 500;
  height: 48px;
  justify-content: center;
  letter-spacing: .25px;
  line-height: normal;
  max-width: 100%;
  overflow: visible;
  padding: 2px 24px;
  position: relative;
  text-align: center;
  text-transform: none;
  transition: box-shadow 280ms cubic-bezier(.4, 0, .2, 1),opacity 15ms linear 30ms,transform 270ms cubic-bezier(0, 0, .2, 1) 0ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: auto;
  will-change: transform,opacity;
  z-index: 0;
}

.button-17:hover {
  background: #F6F9FE;
  /*color: #174ea6;*/
  color: #001D4A;
  border: 2px solid #001D4A;
}

.button-17:active {
  box-shadow: 0 4px 4px 0 rgb(60 64 67 / 30%), 0 8px 12px 6px rgb(60 64 67 / 15%);
  outline: none;
}

.button-17:focus {
  outline: none;
  /*border: 2px solid #4285f4;*/
  border: 2px solid #001D4A;
}

.button-17:not(:disabled) {
  box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
}

.button-17:not(:disabled):hover {
  box-shadow: rgba(60, 64, 67, .3) 0 2px 3px 0, rgba(60, 64, 67, .15) 0 6px 10px 4px;
}

.button-17:not(:disabled):focus {
  box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
}

.button-17:not(:disabled):active {
  box-shadow: rgba(60, 64, 67, .3) 0 4px 4px 0, rgba(60, 64, 67, .15) 0 8px 12px 6px;
}

.button-17:disabled {
  box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
}







/* CSS */
.button-4 {
  appearance: none;
  background-color: #FAFBFC;
  border: 1px solid rgba(27, 31, 35, 0.15);
  border-radius: 6px;
  box-shadow: rgba(27, 31, 35, 0.04) 0 1px 0, rgba(255, 255, 255, 0.25) 0 1px 0 inset;
  box-sizing: border-box;
  color: #24292E;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system, system-ui, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  list-style: none;
  padding: 6px 16px;
  position: relative;
  transition: background-color 0.2s cubic-bezier(0.3, 0, 0.5, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  white-space: nowrap;
  word-wrap: break-word;
}

.button-4:hover {
  background-color: #F3F4F6;
  text-decoration: none;
  transition-duration: 0.1s;
  color: #001D4A;
}

.button-4:disabled {
  background-color: #FAFBFC;
  border-color: rgba(27, 31, 35, 0.15);
  color: #959DA5;
  cursor: default;
}

.button-4:active {
  background-color: #EDEFF2;
  box-shadow: rgba(225, 228, 232, 0.2) 0 1px 0 inset;
  transition: none 0s;
}

.button-4:focus {
  outline: 1px transparent;
}

.button-4:before {
  display: none;
}

.button-4:-webkit-details-marker {
  display: none;
}




/* quiz username input card */
.quiz-card {
  width: 370px;
  height: 254px;
  padding: 0 15px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 12px;
  background: #fff;
  border-radius: 20px;
  margin: 8rem auto;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
}

.quiz-card > * {
  margin: 0;
}

.quiz-card__title {
  font-size: 27px;
  font-weight: 900;
  color: #001D4A;
}

.quiz-card__content {
  font-size: 17px;
  line-height: 18px;
  color: #001D4A; /*#333*/
}

.quiz-card__form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.quiz-card__form input {
  margin-top: 10px;
  outline: 0;
  background: rgb(255, 255, 255);
  box-shadow: transparent 0px 0px 0px 1px inset;
  padding: 0.6em;
  border-radius: 14px;
  border: 1px solid #001D4A;
  color: black;
}

.quiz-card__form button {
  border: 0;
  background: #001D4A;
  color: #fff;
  padding: 0.68em;
  border-radius: 14px;
  font-weight: bold;
}

.sign-up:hover {
  opacity: 0.8;
} 
/* end of scam quiz username input card */






/*help and support*/
.empty-line {
  margin-bottom: 1rem; /* Adjust margin as needed */
}

.card {
  width: 850px;
  height: 100%;
  background: #f7f9f9;
  padding: 50px;
  padding-top: 30px;
  padding-bottom: 30px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center; /*space-evenly*/
  align-items: flex-start; /*flex-start*/
  margin: 3rem auto;
  box-shadow: 20px 20px 30px rgba(0, 0, 0, .05);
}

.card__title {
  font-size: 1.4em;
  font-weight: 500;
  margin-bottom: 0.85rem;
}

.card__text {
  /*font-weight: 500;*/
  color: #6B7280;
  font-size: 1rem;
  line-height: 1.85rem;
  text-align: justify;
  text-justify: inter-word;
}

.card__button {
  cursor: pointer;
  background-color: #27476E;
  color: #f7f9f9;
  font-size: 0.9rem;
  border: none;
  padding: 8px 17px;
  border-radius: 15px;
}

.card__button:hover {
  background-color: #001D4A;
}
/*end of help and support top long cards*/

/* small cards */
.hs-card {
  --bg: rgba(0, 105, 146, 0.1); /*#f7f7f8;*/
  --hover-bg: #AEECEF;
  --hover-text: #001D4A;
  max-width: 25ch;
  text-align: center;
  background: var(--bg);
  padding: 1.5em;
  padding-block: 1.8em;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  transition: .3s cubic-bezier(.6,.4,0,1),transform .15s ease;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin: 1rem;
  margin-top: 0;
  border: 1px solid #001D4A;
  box-shadow: 20px 20px 30px rgba(0, 0, 0, .06);
}

.hs-card__body {
  color: #464853;
  line-height: 1.5rem;
  font-size: 1rem;
  /*text-align: justify;
  text-justify: inter-word;*/
}

.hs-card > :not(span) {
  transition: .3s cubic-bezier(.6,.4,0,1);
}

.hs-card > strong {
  display: block;
  font-size: 1.4rem;
  letter-spacing: -.035rem;
}

.hs-card span {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--hover-text);
  border-radius: 5px;
  font-weight: bold;
  top: 100%;
  transition: all .3s cubic-bezier(.6,.4,0,1);
}

.hs-card:hover span {
  top: 0;
  font-size: 1.2rem;
}

.hs-card:hover {
  background: var(--hover-bg);
}

.hs-card:hover > div,.hs-card:hover > strong {
  opacity: 0;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px; 
  width: 25px;
}

.icon img {
  object-fit: contain; /* cover or 'contain' */
  width: 100%;
  height: 100%;
}
/* end of help and support */


/*bottom nav bar*/
.radio-input input {
  display: none;
}

.radio-input {
  --container_width: 700px;
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 9999px;
  background-color: #fff;
  color: #000000;
  width: var(--container_width);
  overflow: hidden;
  border: 1px solid rgba(53, 52, 52, 0.226);
  justify-content: center;
  margin: 1rem auto;
}

.radio-input label {
  width: 100%;
  padding: 11px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  font-weight: 400;
  letter-spacing: -1px;
  font-size: 16px;
  /*border: 1px solid rgba(53, 52, 52, 0.226);*/
}

.radio-input label:hover {
  background-color: #001D4A;
  color: #fff;
}

.radio-input a {
  text-decoration: none; /* Remove underline */
  color: inherit; /* Inherit color from parent */
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  letter-spacing: -1px;
  font-size: 16px;
}

.radio-input a:hover {
  background-color: #001D4A;
  color: #fff;
}

.selection {
  display: none;
  position: absolute;
  height: 100%;
  width: calc(var(--container_width) / 6);
  z-index: 0;
  left: 0;
  top: 0;
  transition: .15s ease;
}

.radio-input label:has(input:checked) {
  color: #fff;
}

.radio-input label:has(input:checked) ~ .selection {
  background-color: #27476E; /*rgb(11 117 223);*/
  display: inline-block;
}

.radio-input label:nth-child(1):has(input:checked) ~ .selection {
  transform: translateX(calc(var(--container_width) * 0/6));
}

.radio-input label:nth-child(2):has(input:checked) ~ .selection {
  transform: translateX(calc(var(--container_width) * 1/6));
}

.radio-input label:nth-child(3):has(input:checked) ~ .selection {
  transform: translateX(calc(var(--container_width) * 2/6));
}

.radio-input label:nth-child(4):has(input:checked) ~ .selection {
  transform: translateX(calc(var(--container_width) * 3/6));
}

.radio-input label:nth-child(5):has(input:checked) ~ .selection {
  transform: translateX(calc(var(--container_width) * 4/6));
}

.radio-input label:nth-child(6):has(input:checked) ~ .selection {
  transform: translateX(calc(var(--container_width) * 5/6));
}
/*end of bottom nav bar*/


.notifications-container {
  width:90%; 
  padding: 12px;
  align-items: start;
  font-family: "Open Sans", sans-serif;
  text-align: justify;
  background: beige;
  border-radius: 8px;
  letter-spacing: 0.05em;
  box-shadow: 0 0 5px -3px #111;
  margin-top: 20px;
  display: flex;
  flex-direction: column; 
}

.scam-introduction {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
  color: #333;
  margin-bottom: 8px; 
}

.scam-keywords {
  font-size: 14px;
  line-height: 1.25rem;
  color: #333;
}


/*detect scam*/
.centered-info {
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  font-weight: 450;
  color: black;
  line-height: 2;
  text-align: center;
  margin-bottom: 2rem;

  /*margin-bottom: 2rem;*/
}

.disclaimer {
  display: flex;
  width: 90%;
  align-items: center;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  text-align: justify;
  padding: 0.7em 1.4em; /* Uniform padding */
  color: black;
  background: beige;
  font-weight: 400;
  letter-spacing: 0.05em;
  border-radius: 10px;
  line-height: 1.5; /* Increased line spacing for better readability */
}

.disclaimer svg {
  margin-right: 6px;
}


.disclaimer:active {
  box-shadow: 0 0.3em 1em -0.5em black;
}
.card-contentx {
  padding: 10px;
  margin-left: 15px;
  margin-right: 15px;
  line-height: 1.95;
  font-family: "Open Sans", sans-serif;
	/*margin-left: 10px; /* Added left margin for better alignment */
}

.check-button {
  outline: none;
  cursor: pointer;
  border: none;
  padding: 0.9rem 2rem;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  position: relative;
  display: inline-block;
  letter-spacing: 0.05rem;
  font-weight: 700;
  font-size: 17px;
  border-radius: 500px;
  overflow: hidden;
  background: #3066be;
  color: #000;
 }
 
 .check-button span {
  position: relative;
  z-index: 10;
  transition: color 0.4s;
 }
 
 .check-button:hover span {
  color: white;
 }
 
 .check-button::before,
 .check-button::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
 }
 
 .check-button::before {
  content: "";
  background: #fff;
  width: 120%;
  left: -10%;
  transform: skew(30deg);
  transition: transform 0.4s cubic-bezier(0.3, 1, 0.8, 1);
 }
 
 .check-button:hover::before {
  transform: translate3d(100%, 0, 0);
 }
 
  .checkclear-container {
    display: flex;
    gap: 10px; /* Creates space between any child elements */
  }
.result-section1 {
  font-family: "Open Sans", sans-serif;
  border-radius: 10px;
  width: 50%;
  max-width: 90%; /* Limit maximum width to ensure it doesn't get too wide */
  margin: 20px auto; /* Center it horizontally and add vertical spacing */
  padding: 15px 20px;
  font-size: 16px;
  letter-spacing: 2px;
  align-items: center; 
  position: relative;
  justify-content: center; 
  text-decoration: none;
  color: #000;
  background-color: #3066be;
  border: 3px solid;
  padding: 0.25em 0.5em;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.05); /* Single layer, more blur, lighter opacity */

}

.centered-textdetect{
  font-family: "Open Sans", sans-serif;
  font-size: 40px;
  font-weight: 500;
  text-shadow: -0.5px 2px 2px #000;  /* Adding shadow with slight offset and blur */
  color: #1e2029;
  line-height: 2;
  text-align: center;
}

.centered-textd {
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  font-weight: 500;
  color: white;
  line-height: 2;
  text-align: center;

  /*margin-bottom: 2rem;*/
}
/*end of detect scam*/


/*---------------------------VIZUALIZATION CSS-------------------------------*/

.hidden {
  opacity: 0.1;
}

.d3-tooltip {
  position: absolute;
  visibility: hidden;
  padding: 6px;
  background: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  pointer-events: none;
}

.legend {
  font-family: 'Arial, sans-serif';
  font-size: 14px;
}

.tooltip {
  pointer-events: none;
  fill: #333;
  font-weight: bold;
}

.visualization-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.impact-cards-container {
  display: flex; /* Arrange children in a single row */
  justify-content: space-around; /* Evenly space out cards */
  flex-wrap: nowrap; /* Keep all cards in one row, prevent wrapping */
  gap: 60px; /* Adjust spacing between cards if necessary */
  margin-top: 20%;
}

.image-info-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  width: 100%;
  padding: 20px;
}

.image-info-container .info-text {
  flex-grow: 1;
  font-size: x-large;
  margin-right: 200px;
  padding: 0 40px;
  background: #ceafff;
  padding: 40px;
  border-radius: 20%;
}

.image-info-container.reverse .info-text {
  margin-left: 200px;
}


.info-image {
  flex-shrink: 0;
  max-width: 300px; /* Adjust based on your image size */
  margin: 0 180px;
}



/* SCAM TREND CSS*/

.vis-heading-container {
  padding: 20px;
}

.vis-heading-container h1{
  color: #000;
}
.vis-heading-container hr {
  border: none; /* Remove default border */
  height: 2px; /* Set the height of the line */
  background-color: black; /* Set the color of the line */
  margin: 20px 0; /* Add margin above and below the line */
}

/* TRIAL FOR CARD CSS*/

.new-viz-card {
  width: 350px;
  height: 250px;
  perspective: 1000px;
  margin: 10px; /* This gives a little space around each card */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  
}

.new-viz-card-inner {
  width: 100%;
  height: 100%;
  position: relative;
  transform-style: preserve-3d;
  transition: transform 0.999s;
}

.new-viz-card:hover .new-viz-card-inner {
  transform: rotateY(180deg);
}

.new-viz-card-front,
.new-viz-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.new-viz-card-front {
  background-color: #aeecef;
  color: #0000;
  display: flex;
  align-items: center;
  border: 10px solid #aeecef;
  border-radius: 10px;
  justify-content: center;
  font-size: 24px;
  transform: rotateY(0deg);
}

.new-viz-card-back {
  background-color: #017b93;
  color: #fff;
  display: flex;
  align-items: center;
  border: 10px solid #017b93;
  border-radius: 10px;
  justify-content: center;
  text-align: justify;
  font-size: 25px;
  transform: rotateY(180deg);
}

.new-viz-card-back p {
  color: #fff;
}

.all-viz-container {
  display: flex;
  flex-direction: row-reverse; /* Reverse the order of the flex items */
}

.cards-container {
  width: 50vw;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px; /* Adjust this value for desired spacing */
  padding-top: 50px;
  flex-wrap: wrap; /* This allows the cards to wrap into a new line on smaller screens */
  margin-right: 2%;
}

.info-image {
  flex-shrink: 0;
  max-width: 300px; /* Adjust based on your image size */
  margin: 0 180px;
}

/*--------------------------------------------------------------------------------------------/



/* ---------------------------------VIZ BELOW THE BANKING CARD---------------------------------- */

.bank-inst-card {
  position: relative;
  width: 270px;
  height: 270px;
  background-color: #017b93;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  perspective: 1000px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.cards .green {
  background-color: #017b93;
  width: 250px;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.cards .card {
  border-radius: 10px;
  color: white;
  cursor: pointer;
}

.cards .card:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(255, 255, 255, 0.2);
}

.bank-inst-card img {
  fill: #333;
  transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.bank-inst-card:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(255, 255, 255, 0.2);
}

.card__content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  background-color: #aeecef;
  transform: rotateX(-90deg);
  transform-origin: bottom;
  transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.bank-inst-card:hover .card__content {
  transform: rotateX(0deg);
}

.card__title {
  margin: 0;
  font-size: 18px;
  color: #333;
  font-weight: 700;
  text-align: center;
}


.card__description {
  margin: 12px 0 0;
  font-size: 18px;
  color: #777;
  line-height: 1.4;
  text-align: justify;
  
  
}

/*SCAM TRENDS INFORMATION CSS*/

.scam-trends-container {
  height: fit-content;
  width: 90%;
  margin-left: 5%;
  border-radius: 10px;
  background-color: #023047;
  color: white;
  padding: 40px;
  font-size: larger;
  box-shadow: #000;
  margin-bottom: 40px;
}

.icon-image {
  width: 30px; /* Adjust size as needed */
  height: 30px;
  margin-right: 10px; /* Space between icon and text */
  vertical-align: middle;
}

/*button to go down scam-trends*/
.button-trendx-down {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 45%;
}

.button-trend-down {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: rgb(20, 20, 20);
  border: none;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 0px 4px rgba(180, 160, 255, 0.253);
  cursor: pointer;
  transition-duration: 0.3s;
  overflow: hidden;
  position: relative;

}

.button-trend-down:hover {
  background-color: #333;
}

.trend-svgIcon-down {
  width: 12px;
  transition-duration: 0.3s;
  transform: rotate(-180deg);
}

.trend-svgIcon-down path {
  fill: white;
}
/*end of button to go down*/